import React from 'react';
import styles from './NavigationHeader.scss';
import PseudoLinks from '../PseudoLinks';
import CartButton from '../CartButton';
import SectionTabs from '../SectionTabs';
import { useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import dataHooks from '../../data-hooks';
import { calculateMenuScrollOffset, scrollToMenuOrSection } from '../Scroller/scrollingUtils';
import FloatingDropdown from '../FloatingDropdown';
import { useHistory } from 'react-router-dom';

export interface NavigationHeaderEntity {
  id: string;
  title?: string;
}

export interface NavigationHeaderProps {
  menus: NavigationHeaderEntity[];
  sections: Record<string, NavigationHeaderEntity[]>;
  initialMenuId?: string;
  initialSectionId?: string;
  showButtonPlaceholder?: boolean;
  isRTL?: boolean;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  menus,
  sections,
  initialMenuId,
  initialSectionId,
  showButtonPlaceholder,
  isRTL,
}) => {
  const [selectedMenuId, setMenuId] = React.useState(initialMenuId || menus[0]?.id);
  const [selectedSectionId, setSectionId] = React.useState(initialSectionId || sections[menus[0]?.id]?.[0]?.id);
  const biLogger = useBi();
  const history = useHistory();
  const { experiments } = useExperiments();
  const isMenuPermalinkEnabled = experiments.enabled('specs.restaurants.ooi-menu-permalink');

  if (menus.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper} data-hook={dataHooks.navigationHeader}>
      <div className={styles.dropdown}>
        <FloatingDropdown
          label=""
          className={styles.floatingDropdown}
          options={menus.map((e) => ({ id: e.id, value: e.title || '', isSelectable: true }))}
          value={selectedMenuId}
          data-hook={dataHooks.navigationHeaderDropdown}
          onChange={(selectedOption) => {
            if (selectedOption.id) {
              biLogger.navigateToMenu({ menuId: selectedOption.id, menuName: selectedOption.value });

              if (isMenuPermalinkEnabled) {
                history.push(`/${selectedOption.id}`);
              } else {
                scrollToMenuOrSection(selectedOption.id);
              }

              setMenuId(selectedOption.id);
              setSectionId(sections[selectedOption.id][0]?.id);
            }
          }}
          displayBlock
        />
        <div className={styles.divider} />
      </div>
      <SectionTabs
        className={styles.tabs}
        dataHook={dataHooks.navigationHeaderTabs}
        sections={sections[selectedMenuId] ? sections[selectedMenuId] : sections[initialMenuId || menus[0]?.id]}
        selectedSectionId={selectedSectionId}
        isRTL={isRTL}
      />
      <div className={styles.cartButton}>{showButtonPlaceholder && <CartButton />}</div>

      <PseudoLinks
        menus={menus}
        sections={sections[selectedMenuId] ? sections[selectedMenuId] : sections[initialMenuId || menus[0]?.id]}
        offset={calculateMenuScrollOffset()}
        onChange={(type, id) => {
          if (type === 'menu') {
            setMenuId(id);
            setSectionId(sections[id][0]?.id);
          } else if (type === 'section') {
            setSectionId(id);
          }
        }}
      />
    </div>
  );
};

NavigationHeader.displayName = 'NavigationHeader';

export default NavigationHeader;
