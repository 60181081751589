import React, { useEffect } from 'react';
import { useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import { ALIGNMENT, SKIN, VARIANT } from 'wix-ui-tpa/Tabs';
import { TabItem } from 'wix-ui-tpa/dist/src/components/Tabs/Tab';
import { scrollToMenuOrSection } from '../Scroller/scrollingUtils';
import Tabs from '../Tabs';
import styles from './SectionTabs.scss';
import { useHistory } from 'react-router-dom';

export interface SectionTabsProps {
  sections: TabItem[];
  dataHook?: string;
  className?: string;
  isRTL?: boolean;
  isMobile?: boolean;
  selectedSectionId?: string;
}

const SectionTabs: React.FC<SectionTabsProps> = ({
  sections,
  isMobile,
  isRTL,
  className,
  dataHook,
  selectedSectionId: initialSelectedSectionId,
}) => {
  const [selectedSectionId, setSectionId] = React.useState(initialSelectedSectionId || sections[0]?.id);
  const biLogger = useBi();
  const history = useHistory();
  const { experiments } = useExperiments();
  const isMenuPermalinkEnabled = experiments.enabled('specs.restaurants.ooi-menu-permalink');

  useEffect(() => {
    if (initialSelectedSectionId) {
      setSectionId(initialSelectedSectionId);
    }
  }, [initialSelectedSectionId]);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Tabs
        data-hook={dataHook}
        aria-label={'Sub menus'}
        activeTabIndex={sections.findIndex((e) => e.id === selectedSectionId)}
        alignment={isRTL ? ALIGNMENT.right : ALIGNMENT.left}
        items={sections}
        onTabClick={(selectedTabIndex) => {
          const sectionId = sections[selectedTabIndex].id;
          const sectionName = sections[selectedTabIndex].title;
          biLogger.navigateToSection({ sectionId, sectionName });
          if (sectionId) {
            if (isMenuPermalinkEnabled) {
              history.push(`/${sectionId}`);
            } else {
              scrollToMenuOrSection(sectionId, isMobile);
            }

            setSectionId(sectionId);
          }
        }}
        skin={SKIN.fullUnderline}
        variant={VARIANT.fit}
      />
    </div>
  );
};

export default SectionTabs;
