import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import dataHooks from '../../data-hooks';
import { CloseModalPayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import SubMenuView from './SubMenuView';
import styles from './MenuView.scss';
import MenuViewEmptyState from '../MenuViewEmptyState';
import StickyCartButton from '../StickyCartButton';
import StickyNavigationHeader from '../StickyNavigationHeader';
import FloatingCartButton from '../FloatingCartButton';
import NotificationBar from '../NotificationBar';
import DispatchSummary from '../DispatchSummary';
import MenuPicker from '../MenuPicker';
import { DisplayableMenu, MenuDisplayOptions } from '@wix/restaurants-client-logic';
import NoBusinessAddressNotification from '../NoBusinessAddressNotification';
import { scrollToMenuOrSection } from '../Scroller/scrollingUtils';
import { useExperiments } from 'yoshi-flow-editor-runtime';

export interface MenuViewProps {
  displayableMenu: DisplayableMenu;
  orderItemsCount: number;
  isMobile?: boolean;
  layout?: 'side-by-side' | 'card';
  menuDisplayOptions?: MenuDisplayOptions;
  isRTL?: boolean;
  isCartModalOpen?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  closeModal: (payload: CloseModalPayload) => void;
}

const MenuView: React.FC<MenuViewProps> = ({
  displayableMenu,
  orderItemsCount,
  isMobile,
  layout,
  menuDisplayOptions,
  isRTL,
  isCartModalOpen,
  openModal,
  closeModal,
}) => {
  const match = useRouteMatch<{ subRoute: string }>();
  const hasMenus = displayableMenu.length > 0;
  const { experiments } = useExperiments();
  const isMenuPermalinkEnabled = experiments.enabled('specs.restaurants.ooi-menu-permalink');

  useEffect(() => {
    if (match.params.subRoute === 'cart' && !isCartModalOpen) {
      openModal({ modal: Modals.CART_MODAL, redirectOnClose: '/' });
    } else if (match.params.subRoute !== 'cart' && isCartModalOpen) {
      closeModal({ modal: Modals.CART_MODAL });
    }

    if (isMenuPermalinkEnabled && match.params.subRoute && match.params.subRoute !== 'cart') {
      scrollToMenuOrSection(match.params.subRoute, isMobile);
    }
  }, [isCartModalOpen, openModal, closeModal, match.params.subRoute, isMobile, isMenuPermalinkEnabled]);

  return (
    <div id={dataHooks.menuViewWrapper} data-hook={dataHooks.menuViewWrapper}>
      <NoBusinessAddressNotification />
      <NotificationBar />
      <DispatchSummary />
      {!isMobile && <StickyCartButton />}
      {!isMobile && <StickyNavigationHeader />}
      {isMobile && displayableMenu.length > 1 && <MenuPicker menus={displayableMenu} />}
      <div className={styles.wrapper} data-hook={dataHooks.menuView}>
        {!hasMenus && <MenuViewEmptyState />}

        {displayableMenu.map((displayableSubMenu) => (
          <SubMenuView
            key={displayableSubMenu.id}
            displayableSubMenu={displayableSubMenu}
            isMobile={isMobile}
            layout={layout}
            menuDisplayOptions={menuDisplayOptions}
            isRTL={isRTL}
          />
        ))}
      </div>
      {isMobile && orderItemsCount > 0 && <FloatingCartButton />}
    </div>
  );
};

MenuView.displayName = 'MenuView';

export default MenuView;
